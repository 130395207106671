/* eslint-disable @nx/enforce-module-boundaries */
import {
  Box,
  Button,
  Divider,
  HStack,
  Heading,
  Stack,
  Text,
  Collapse,
} from '@mybridge/ui';
import { forwardRef, useContext, useEffect } from 'react';
import { SearchContext } from '../context';
import { SearchPostsFilters } from './filters';
import { SearchPostsListing } from './listing';
import { isMobile } from 'react-device-detect';
import { useDisclosure } from '@mybridge/ui';
import { IconButton } from '@chakra-ui/react';
import { Filters } from '@mybridge/icons';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';
export const SearchPosts = forwardRef(({ count, listing, ...props }, ref) => {
  const {
    postsFetchNextPage,
    postsHasNextPage,
    postsSearchIsFetching,
    postsSearchIsLoading,
    postsTotalCount,
    query,
    refetchPostsSearch,
    loggedIn,
    userLoggedOut
  } = useContext(SearchContext);
  const filterDisc = useDisclosure();
  const { t } = useTranslator();
  useEffect(() => {
    refetchPostsSearch();
  }, []);

  useEffect(() => {
    refetchPostsSearch();
  }, [loggedIn, userLoggedOut]);

  return (
    // <Box w="100%">
    <Stack>
      <Stack spacing={0}>
        {query?.query?.length && isMobile ? (
          <Collapse style={{overflow:"inherit"}}  in={filterDisc?.isOpen} animateOpacity>
            <Box overflowX="auto">
              <SearchPostsFilters />
            </Box>
            <Divider my={2} />
          </Collapse>
        ) : (
          ''
        )}
        {query?.query?.length ? (
          <HStack w="100%"
          justifyContent="space-between"
          alignItems="center"
          gap={0}>
            <Heading fontSize="20px" color="brandPrimary.500" fontWeight="400">{t('createPage.posts') || "Posts"}</Heading>
            {isMobile && <IconButton icon={<Filters />} onClick={() => filterDisc.onToggle()} variant="transparent" />}
          </HStack>
        ) : (
          ''
        )}
        {query?.query?.length && postsTotalCount ? (
          <Text size="sm">{postsTotalCount} {t('pageJobs.results') || "results"}</Text>
        ) : (
          ''
        )}
      </Stack>
          
      <SearchPostsListing />
      {/* {postsHasNextPage ? (
        <HStack justifyContent="center">
          <Button
            isLoading={postsSearchIsFetching}
            onClick={postsFetchNextPage}
            variant="secondary"
          >
            Load More
          </Button>
        </HStack>
      ) : (
        ''
      )} */}
    </Stack>
    // </Box>
  );
});
